import * as React from 'react';
import {
  Flex,
  Box,
  Text,
  useBreakpointValue,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import FadeIn from '../components/FadeIn';

export default function Introduction() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      px={4}
      pt={8}
      w="full"
      justify="space-between"
      mx="auto"
      direction={isMobile ? 'column' : 'row'}
    >
      <Flex justify="center" align="center">
        <Box py={4}>
          <FadeIn>
            <Heading
              color="blue"
              fontFamily="Haetten, Sawarabi Gothic, sans-serif"
              fontSize={{ base: '4xl', md: '4xl', xl: '6xl' }}
              letterSpacing={2}
              fontWeight={400}
              mb={{ base: 8, md: 12 }}
            >
              Introduction
            </Heading>
            <Stack spacing={{ base: 8, md: 12 }}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                コード（和音）の響きをインスピレーションの源泉に、さまざまなデザインのチョーカーを作成して参ります。
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                ロックの力強さを感じさせるパワーコード、アンビエント漂うテンションコード…
                <br />
                魂を揺さぶるディストーションギター、情感を彩るアコースティックピアノの旋律…
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                音楽からのイマジネーションをチョーカーに。
                <br />
              </Text>
              <StaticImage
                src="../images/introduction.png"
                alt="Choker Joker Introduction"
                quality={60}
                layout="fullWidth"
              />
            </Stack>
          </FadeIn>
        </Box>
      </Flex>
    </Flex>
  );
}
