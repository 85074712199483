import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

type FadeInProps = {
  id?: string;
  children: React.ReactNode;
};

const FadeIn = ({ id, children }: FadeInProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから~px過ぎたらinViewがtrue
    triggerOnce: true, // 最初の一度だけ実行
  });

  return (
    <Box
      id={id || undefined}
      ref={ref}
      opacity={inView ? 100 : 0}
      translateY={inView ? undefined : '50%'}
      transitionDuration="1.5s"
    >
      {children}
    </Box>
  );
};

export default FadeIn;
