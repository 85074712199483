import * as React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Grid, GridItem, useBreakpointValue, Text } from '@chakra-ui/react';
import ProductPreviewCard from './ProductPreviewCard';
import { StaticImage } from 'gatsby-plugin-image';

const query = graphql`
  query ShopifyPickUpChokerProducts {
    pickUpChoker: allShopifyCollection(
      filter: { id: { eq: "7cdd6e21-d4e0-5601-b487-a618ec0e8a0a" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
  }
`;

const PickUpChokerListing = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const queriedProducts =
    useStaticQuery<GatsbyTypes.ShopifyPickUpChokerProductsQuery>(query);
  const products = queriedProducts.pickUpChoker.nodes[0]?.products;

  return (
    <Grid templateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}>
      {products?.map(p => (
        <GridItem w="100%" key={p?.shopifyId}>
          <ProductPreviewCard
            image={p?.images?.[0]}
            title={p?.title}
            price={p?.priceRangeV2?.minVariantPrice?.amount}
            currency={p?.priceRangeV2?.minVariantPrice?.currencyCode}
            handle={p?.handle}
          />
        </GridItem>
      ))}
      <GridItem
        w="100%"
        p={2}
        onClick={() => navigate('/store')}
        boxShadow="xl"
        _hover={{
          cursor: 'pointer',
        }}
      >
        <StaticImage src="../images/go_to_store.png" alt="Go to Store" />
      </GridItem>
    </Grid>
  );
};

export default PickUpChokerListing;
