import React from 'react';
import { Heading, Box } from '@chakra-ui/react';
import { maxW } from '../assets/config';
import Layout from '../components/layout';
import PickUpChokerListing from '../components/PickUpChokerListing';
import Seo from '../components/seo';
import KeyVisualTop from '../components/KeyVisualTop';
import Introduction from '../features/Introduction';
import JsonLd from '../components/JsonLd';

const IndexPage = () => (
  <Layout full={true}>
    <Seo
      title="Choker Joker"
      description="国内外パーツセレクトのオリジナルチョーカー専門店です"
    />
    <JsonLd
      title="Choker Joker"
      description="国内外パーツセレクトのオリジナルチョーカー専門店です"
    />
    <KeyVisualTop />
    <Box px={4} maxW={maxW} w="full" mx="auto">
      <Introduction />
      <Heading
        fontFamily="Haetten, Sawarabi Gothic, sans-serif"
        letterSpacing={2}
        fontWeight={400}
        fontSize="6xl"
        textAlign="center"
        my={8}
      >
        〜 <u>PICK UP</u>〜
      </Heading>
      <PickUpChokerListing />
    </Box>
  </Layout>
);

export default IndexPage;
