import * as React from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

export default function KeyVisual() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      bg="black"
      justify="center"
      pt={isMobile ? 0 : 20}
      pb={isMobile ? 4 : 20}
    >
      <Box w={isMobile ? '100%' : '80%'}>
        <StaticImage src="../images/kv_top.png" alt="" layout="fullWidth" />
      </Box>
    </Flex>
  );
}
